<template lang="pug">
.manage-member

  v-card.card-simple.rounded-xl.mb-10(
    v-if="max_members <= 0"
  )
    v-list(color="transparent")
      v-list-item(color="secondary")
        v-list-item-avatar.mr-3.my-0
          v-icon(
            color="warning"
            large
          ) mdi-package-variant-closed-remove
        v-list-item-content.py-0
          v-list-item-title Du mangler abonnement
          v-list-item-subtitle For å kunne aktivere dine brukere vennligst opprett et abonnement.
        v-list-item-action.ma-0
          v-tooltip(
            transition="drop-front"
            color="black"
            bottom
          )
            span Gå til abonnement
            template(#activator="{ on, attrs }")
              v-btn(
                v-bind="attrs"
                v-on="on"
                to="/billing/subscription"
                icon
              )
                v-icon mdi-arrow-right-thin
  .mb-8(v-else)
    v-progress-linear(
      :value="(enabled_members.length / max_members) * 100"
      height="10"
      color="secondary"
      rounded
    )
    .text-center.mt-3
      v-chip(
        color="secondary"
        label small
      ) {{ enabled_members.length }} / {{ max_members }}

  v-card.card-simple.rounded-xl.mb-4(
    v-for="(member) in members"
    :key="`member-${member.sub}`"
  )
    v-list(color="transparent")
      v-list-item(color="secondary")
        v-list-item-avatar.mr-3.my-0
          v-icon(
            color="#80dbc1"
            large
          ) mdi-account-circle
        v-list-item-content.py-0
          v-list-item-title
            .d-inline.mr-2 {{ member.nickname || member.email }}
            v-chip.px-2.mr-2(
              v-if="member.company_owner"
              color="#5a3de6"
              dark x-small
            ) Admin
            v-chip.px-2.mr-2(
              v-if="isManager(member)"
              color="#f9ab00"
              dark x-small
            ) Manager
            v-chip.px-2(
              v-if="member.status !== 'CONFIRMED'"
              color="#fec8bf"
              x-small
            ) Ikke aktivert
          v-list-item-subtitle
            .d-inline.mr-2(v-if="member.nickname") {{ member.email }}

        v-list-item-action.ma-0(v-if="!member.company_owner")
          v-tooltip(
            transition="drop-front"
            color="black"
            bottom
          )
            span Aktiver/deaktiver bruker
            template(#activator="{ on, attrs }")
              div(
                v-bind="attrs"
                v-on="on"
              )
                v-switch(
                  v-model="enabled_proxy[member.sub]"
                  @change="e => toggleMemberEnabled(member, e)"
                  :disabled="!enabled_proxy[member.sub] && !can_enable"
                  color="secondary"
                  flat inset dense
                )
        v-list-item-action.ma-0(v-if="member.manageable")
          v-tooltip(
            transition="drop-front"
            color="black"
            bottom
          )
            span {{ isManager(member) ? 'Fjern manager-rolle' : 'Legg til manager-rolle' }}
            template(#activator="{ on, attrs }")
              v-btn(
                v-bind="attrs"
                v-on="on"
                :disabled="loading"
                @click="doToggleMemberManager(member.sub)"
                icon
              )
                v-icon {{ isManager(member) ? 'mdi-account-minus' : 'mdi-account-plus' }}
        v-list-item-action.ma-0(v-if="member.transferable")
          v-tooltip(
            transition="drop-front"
            color="black"
            bottom
          )
            span Overfør admin
            template(#activator="{ on, attrs }")
              v-btn(
                v-bind="attrs"
                v-on="on"
                :disabled="loading"
                @click="doTransferCompany(member.sub)"
                icon
              )
                v-icon mdi-swap-horizontal
        v-list-item-action.ma-0(v-if="member.deletable")
          v-tooltip(
            transition="drop-front"
            color="black"
            bottom
          )
            span Fjern bruker
            template(#activator="{ on, attrs }")
              v-btn(
                v-bind="attrs"
                v-on="on"
                :disabled="loading"
                @click="doDeleteMember(member.sub)"
                icon
              )
                v-icon mdi-close
</template>

<script>
import { get } from 'lodash'
import { mapState, mapActions } from 'vuex'
import { cloneDeep } from '@/lib/utils'

export default {
  name: 'ManageMember',
  data: () => ({
    loading: false,
    enabled_proxy: {}
  }),
  computed: {
    ...mapState('Member', ['members']),
    ...mapState('Company', ['company']),
    max_members() {
      return get(this.company, 'stripe_feature_object.max_members', 0)
    },
    enabled_members() {
      return get(this.company, 'enabled_members', [])
    },
    can_enable() {
      return this.enabled_members.length < this.max_members
    }
  },
  watch: {
    company: {
      immediate: true,
      handler() {
        const tmp = cloneDeep(this.enabled_proxy)

        // Reset
        for (const member of this.members) {
          tmp[member.sub] = false
        }

        // Patch from enabled_members
        if (this.enabled_members.length > 0) {
          for (const member_id of this.enabled_members) {
            tmp[member_id] = true
          }
        }

        this.enabled_proxy = cloneDeep(tmp)
      }
    }
  },
  methods: {
    ...mapActions('App', ['setSnackbar']),
    ...mapActions('Member', [
      'listMember',
      'deleteMember',
      'toggleManagerMember'
    ]),
    ...mapActions('Company', [
      'readCompany',
      'transferCompany',
      'enableMember',
      'disableMember'
    ]),
    isManager(member) {
      return get(member, 'groups', []).includes(
        process.env.VUE_APP_USER_POOL_GROUP_MANAGER
      )
    },
    async toggleMemberEnabled(member, v) {
      const { sub } = member

      // Needed since @change is used by v-model
      const tmp = cloneDeep(this.enabled_proxy)
      tmp[sub] = v
      this.enabled_proxy = cloneDeep(tmp)

      try {
        const is_enabled = get(this.enabled_proxy, `[${sub}]`, false) // default false for disable action
        if (is_enabled) await this.enableMember(sub)
        else await this.disableMember(sub)
      } catch (e) {
        this.setSnackbar({
          type: 'error',
          msg: e.message
        })
      }
    },
    async doToggleMemberManager(sub) {
      if (confirm('Er du sikker?')) {
        try {
          await this.toggleManagerMember(sub)
          await this.listMember()
        } catch (e) {
          this.setSnackbar({
            type: 'error',
            msg: e.message
          })
        }
      }
    },
    async doTransferCompany(sub) {
      if (
        !confirm(
          'Er du sikker på at du ønsker å overføre administratør-egenskapene?'
        )
      )
        return

      this.loading = true
      try {
        await this.transferCompany({ sub })
        window.location.reload()
      } catch (e) {
        this.setSnackbar({
          type: 'error',
          msg: e.message
        })
      } finally {
        this.loading = false
      }
    },
    async doDeleteMember(sub) {
      if (confirm('Er du sikker på at du ønsker å slette denne brukeren?')) {
        try {
          await this.deleteMember(sub)
          await this.readCompany() // So that enabled deleted member is reflected in count
        } catch (e) {
          this.setSnackbar({
            type: 'error',
            msg: e.message
          })
        }
      }
    }
  },
  async mounted() {
    try {
      await this.listMember()
    } catch (e) {
      // NOP
    }
  }
}
</script>

<style lang="stylus" scoped></style>
