<template lang="pug">
v-form(
  v-model="form"
  @submit.prevent
)
  v-text-field.mb-2(
    :disabled="loading"
    :rules="[rules.required('Oppgi en epost'), rules.validEmail]"
    @keyup.enter="submit"
    @click:append="submit"
    v-model="email"
    ref="email"
    type="email"
    placeholder="Inviter bruker via epost"
    append-icon="mdi-send"
    rounded filled
  )
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { rules } from '@/mixins'

export default {
  name: 'InviteMember',
  mixins: [rules],
  data: () => ({
    loading: false,
    form: false,
    email: ''
  }),
  computed: mapGetters('Cognito', ['userAttributes']),
  methods: {
    ...mapActions('App', ['setSnackbar']),
    ...mapActions('Member', ['createMember']),
    async submit() {
      if (!this.form) return

      // Validate
      if (this.userAttributes.email === this.email) {
        this.email = null
        this.setSnackbar({
          type: 'error',
          msg: 'Du kan ikke invitere deg selv'
        })
        return
      }

      this.loading = true
      try {
        await this.createMember({ email: this.email })
        this.$refs.email.reset()
      } catch (e) {
        this.setSnackbar({
          type: 'error',
          msg: e.message
        })
      } finally {
        this.email = null
        this.loading = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped></style>
