var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"manage-member"},[(_vm.max_members <= 0)?_c('v-card',{staticClass:"card-simple rounded-xl mb-10"},[_c('v-list',{attrs:{"color":"transparent"}},[_c('v-list-item',{attrs:{"color":"secondary"}},[_c('v-list-item-avatar',{staticClass:"mr-3 my-0"},[_c('v-icon',{attrs:{"color":"warning","large":""}},[_vm._v("mdi-package-variant-closed-remove")])],1),_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',[_vm._v("Du mangler abonnement")]),_c('v-list-item-subtitle',[_vm._v("For å kunne aktivere dine brukere vennligst opprett et abonnement.")])],1),_c('v-list-item-action',{staticClass:"ma-0"},[_c('v-tooltip',{attrs:{"transition":"drop-front","color":"black","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"to":"/billing/subscription","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-right-thin")])],1)]}}],null,false,3675736994)},[_c('span',[_vm._v("Gå til abonnement")])])],1)],1)],1)],1):_c('div',{staticClass:"mb-8"},[_c('v-progress-linear',{attrs:{"value":(_vm.enabled_members.length / _vm.max_members) * 100,"height":"10","color":"secondary","rounded":""}}),_c('div',{staticClass:"text-center mt-3"},[_c('v-chip',{attrs:{"color":"secondary","label":"","small":""}},[_vm._v(_vm._s(_vm.enabled_members.length)+" / "+_vm._s(_vm.max_members))])],1)],1),_vm._l((_vm.members),function(member){return _c('v-card',{key:("member-" + (member.sub)),staticClass:"card-simple rounded-xl mb-4"},[_c('v-list',{attrs:{"color":"transparent"}},[_c('v-list-item',{attrs:{"color":"secondary"}},[_c('v-list-item-avatar',{staticClass:"mr-3 my-0"},[_c('v-icon',{attrs:{"color":"#80dbc1","large":""}},[_vm._v("mdi-account-circle")])],1),_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',[_c('div',{staticClass:"d-inline mr-2"},[_vm._v(_vm._s(member.nickname || member.email))]),(member.company_owner)?_c('v-chip',{staticClass:"px-2 mr-2",attrs:{"color":"#5a3de6","dark":"","x-small":""}},[_vm._v("Admin")]):_vm._e(),(_vm.isManager(member))?_c('v-chip',{staticClass:"px-2 mr-2",attrs:{"color":"#f9ab00","dark":"","x-small":""}},[_vm._v("Manager")]):_vm._e(),(member.status !== 'CONFIRMED')?_c('v-chip',{staticClass:"px-2",attrs:{"color":"#fec8bf","x-small":""}},[_vm._v("Ikke aktivert")]):_vm._e()],1),_c('v-list-item-subtitle',[(member.nickname)?_c('div',{staticClass:"d-inline mr-2"},[_vm._v(_vm._s(member.email))]):_vm._e()])],1),(!member.company_owner)?_c('v-list-item-action',{staticClass:"ma-0"},[_c('v-tooltip',{attrs:{"transition":"drop-front","color":"black","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-switch',{attrs:{"disabled":!_vm.enabled_proxy[member.sub] && !_vm.can_enable,"color":"secondary","flat":"","inset":"","dense":""},on:{"change":function (e) { return _vm.toggleMemberEnabled(member, e); }},model:{value:(_vm.enabled_proxy[member.sub]),callback:function ($$v) {_vm.$set(_vm.enabled_proxy, member.sub, $$v)},expression:"enabled_proxy[member.sub]"}})],1)]}}],null,true)},[_c('span',[_vm._v("Aktiver/deaktiver bruker")])])],1):_vm._e(),(member.manageable)?_c('v-list-item-action',{staticClass:"ma-0"},[_c('v-tooltip',{attrs:{"transition":"drop-front","color":"black","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.loading,"icon":""},on:{"click":function($event){return _vm.doToggleMemberManager(member.sub)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.isManager(member) ? 'mdi-account-minus' : 'mdi-account-plus'))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.isManager(member) ? 'Fjern manager-rolle' : 'Legg til manager-rolle'))])])],1):_vm._e(),(member.transferable)?_c('v-list-item-action',{staticClass:"ma-0"},[_c('v-tooltip',{attrs:{"transition":"drop-front","color":"black","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.loading,"icon":""},on:{"click":function($event){return _vm.doTransferCompany(member.sub)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-swap-horizontal")])],1)]}}],null,true)},[_c('span',[_vm._v("Overfør admin")])])],1):_vm._e(),(member.deletable)?_c('v-list-item-action',{staticClass:"ma-0"},[_c('v-tooltip',{attrs:{"transition":"drop-front","color":"black","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.loading,"icon":""},on:{"click":function($event){return _vm.doDeleteMember(member.sub)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v("Fjern bruker")])])],1):_vm._e()],1)],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }