<template lang="pug">
.member
  .text-h5.font-weight-bold.primary--text Brukere
  .text-subtitle-2.mt-2.mb-8 Inviterte brukere vil kunne benytte seg av ditt abonnement.

  template(v-if="loading")
    v-skeleton-loader.mt-10.mb-3(type="heading")
    v-skeleton-loader(type="text@3")

  template(v-else)
    invite-member
    manage-member
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { InviteMember, ManageMember } from '@/components/member'

export default {
  name: 'Member',
  components: {
    InviteMember,
    ManageMember
  },
  data: () => ({
    loading: true
  }),
  computed: {
    ...mapGetters('App', ['isCompanyOwner', 'isCompanyManager']),
    allowedAccess() {
      return this.isCompanyOwner || this.isCompanyManager
    }
  },
  watch: {
    allowedAccess: {
      immediate: true,
      handler(v) {
        if (!v) this.$router.push('/search')
      }
    }
  },
  methods: mapActions('Company', ['readCompany']),
  async mounted() {
    this.loading = true

    try {
      await this.readCompany()
    } catch (e) {
      // NOP
    } finally {
      this.loading = false
    }
  }
}
</script>

<style lang="stylus" scoped></style>
